@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Noto+Sans+Tamil:wght@100..900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-display: swap;
}

.link {
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, #0a78b9, #0a78b9 50%, #fff 0);
  background-position: -100%;
  background-size: 200% 100%;
  display: inline-block;
  -webkit-text-fill-color: transparent;
}

.link,
.link:before {
  transition: 0.3s ease-in-out;
}

.link:hover {
  background-position: 0;
}

.fixed-nav .link,
.nav-text .dropdown-link .link {
  background-image: linear-gradient(90deg, #0a78b9, #0a78b9 50%, #000 0);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  transition: padding 0.15s ease-in-out, 0.1s box-shadow 0.25s ease-in-out,
    0.15s position 0.15s ease-in-out;
  box-shadow: 0 8px 10px 0 rgba(0, 84, 166, 0.1);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 80%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.bounce {
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
}

.bounce:hover {
  transform: translate3d(0, -10px, 0) scaleY(1);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.animate-spin-slow {
  animation: spin 4s linear infinite;
}

.animate-spin-reverse-slower {
  animation: spin-reverse 6s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes spin-reverse {
  100% {
    transform: rotate(-1turn);
  }
}

.hover-img-div:hover {
  flex-shrink: 0;
}

.hover-img-div:hover .image {
  border-radius: 8px;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-8%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.swiper-pagination {
  bottom: 40px !important;
}

/* Swiper pagination bullets customization */
.swiper-pagination-bullet {
  background-color: #fff !important;
  opacity: 1 !important;
}

.custom-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #0a78b9 !important;
}

.custom-pagination-bullet {
  width: 9px !important;
  height: 9px !important;
  background-color: #fff;
  /* Hide the default background */
  border-radius: 50%;
  position: relative;
  display: inline-block !important;
  margin: 0 5px !important;
  cursor: pointer !important;
}

/* Loader styles */
.custom-pagination-bullet .loader {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #0a78b9;
  border-top: 2px solid transparent;
  animation: spin-loader 1s linear infinite;
  animation-delay: 0.3s;
  top: -4px;
  left: -4px;
  display: none;
  /* Hide loader by default */
}

/* Show loader only on the active pagination bullet */
.swiper-pagination-bullet-active .loader {
  display: block;
}

/* Animation for loader */
@keyframes spin-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* marquee.css */
.slide-animation-logos {
  animation: 10s slides infinite linear;
}

@keyframes slides {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.hover-img-div {
  transition: 1s;
}

.content-layer {
  visibility: hidden;
}

.hover-img-div:hover .content-layer {
  visibility: visible;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@keyframes fadeInDropdown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.drop-down-animation {
  animation-name: fadeInDropdown;
  animation-duration: 0.5s;
  animation-delay: 0.3s;
}

.swiper-scrollbar-drag {
  background: rgb(152 152 152 / 50%) !important;
}

.swiper-scrollbar.swiper-scrollbar-horizontal {
  background: rgb(0 0 0 / 8%) !important;
  height: 3px !important;
}

.bg-neutral-liner {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.56) 36.78%,
    rgba(99, 97, 97, 0.35) 71.22%,
    rgba(137, 137, 137, 0.28) 100.68%
  );
}

.about-us-page .nav-text {
  background-color: #000;
}

.blogs-page .nav-text {
  background-color: #000;
}

.link:hover .chevron-right {
  filter: invert(23%) sepia(100%) saturate(7500%) hue-rotate(190deg)
    brightness(95%) contrast(100%);
}

@media (min-width: 320px) and (max-width: 700px) {
  .marquee {
    grid-template-columns: repeat(5, minmax(12vmin, 1fr)) !important;
  }

  .benefits-swiper .swiper-slide {
    width: 97% !important;
  }
}

@media (min-width: 320px) and (max-width: 1020px) {
  .link {
    -webkit-background-clip: text;
    background-image: linear-gradient(90deg, #0a78b9, #0a78b9 50%, #000000 0);
  }

  #root {
    overflow: hidden;
  }

  .mobile-slide-content {
    left: 25px !important;
    bottom: 85px !important;
    top: auto !important;
    max-width: 330px;
  }

  .rise-object {
    object-fit: contain !important;
  }

  .modal-iframe .search-iframe {
    height: calc(100vh - 325px) !important;
  }
}

.dot-point::before {
  content: "";
  background-color: #3b82f6;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 4px;
  border-radius: 50%;
}

.text-pink-grd {
  background: linear-gradient(96deg, #ff83cd 10.64%, #db3699 98.55%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-orange-grd {
  background: linear-gradient(96deg, #ff852c 10.64%, #efb214 98.55%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-linear-grd {
  background: linear-gradient(180deg, #b4b4b4 0%, #f7f7f7 100%);
}

.shadow-rise {
  box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.08),
    0px 4px 24px 0px rgba(0, 0, 0, 0.08);
}

.bg-grd-linear {
  background: linear-gradient(180deg, #f5f5f5 0%, #f1f1f1 100%);
}

/* Swiper container styling */
.app-coverflow.mySwiper,
.testimonial-coverflow.mySwiper {
  width: 100%;
  height: 100%;
}

.app-coverflow.swiper-3d .swiper-slide-shadow-left,
.app-coverflow.swiper-3d .swiper-slide-shadow-right,
.testimonial-coverflow.swiper-3d .swiper-slide-shadow-left,
.testimonial-coverflow.swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to left, rgb(0 0 0 / 0%), rgba(0, 0, 0, 0));
  display: none !important;
}

.app-coverflow.swiper-3d .swiper-slide img {
  display: block;
  width: 100%;
  padding-left: 62px;
  padding-right: 62px;
}

.testimonial-coverflow.swiper-3d .swiper-slide {
  padding-left: 40px;
  padding-right: 40px;
  height: 320px;
  width: 320px;
}

.app-coverflow.swiper-3d .swiper-slide {
  background-position: center;
  background-size: cover;
  border-radius: 8px;
}

.evoke-opacity {
  background: linear-gradient(
    180deg,
    rgba(247, 247, 247, 0.4) 0%,
    rgba(180, 180, 180, 0.4) 100%
  );
  mix-blend-mode: multiply;
}

.bg-liner-opacity {
  background: linear-gradient(180deg, #f7f7f7 0%, #b4b4b4 100%);
  mix-blend-mode: multiply;
}

.rise-series .nav-text {
  border-bottom: 1.6px solid rgb(238 238 238);
  background: radial-gradient(
    609.94% 516.16% at 42.04% 17.78%,
    rgb(192 192 192) 18.5%,
    rgb(176 175 175) 100%
  );
  backdrop-filter: blur(6px);
}

.rise-series .nav-text img.h-7.w-auto {
  filter: invert(1) brightness(100) hue-rotate(575deg) contrast(1) saturate(100);
}

.rise-series .nav-text .link {
  background-image: linear-gradient(90deg, #0a78b9, #0a78b9 50%, #000 0);
}

.modal-iframe .search-iframe {
  height: calc(100vh - 130px);
}

.blog-border-1::after {
  content: "";
  border: 1px solid #dddddd;
  position: absolute;
  right: -17px;
  height: 200px;
  top: 30px;
  border-radius: 24px;
}

.testimonial-coverflow.swiper-3d .swiper-slide-shadow-left,
.testimonial-coverflow.swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to left, rgb(0 0 0 / 0%), rgba(0, 0, 0, 0));
  display: none !important;
}

.product-blog .blog-one {
  display: none;
}

.font-bebas {
  font-family: "Bebas Neue", serif;
  font-style: normal;
}

.bg-clip {
  clip-path: polygon(
    74.1% 44.1%,
    100% 61.6%,
    97.5% 26.9%,
    85.5% 0.1%,
    80.7% 2%,
    72.5% 32.5%,
    60.2% 62.4%,
    52.4% 68.1%,
    47.5% 58.3%,
    45.2% 34.5%,
    27.5% 76.7%,
    0.1% 64.9%,
    17.9% 100%,
    27.6% 76.8%,
    76.1% 97.7%,
    74.1% 44.1%
  );
}

.animate-zoom-in {
  animation: zoom-in 0.6s ease-in-out;
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.product-slider-list .swiper-button-prev {
  background-color: #ffffff;
  left: 0;
  width: 40px;
  height: 65px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.product-slider-list .swiper-button-next {
  background-color: #ffffff;
  right: 0;
  width: 40px;
  height: 65px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.product-slider-list .swiper-button-prev:after,
.product-slider-list .swiper-button-next:after {
  font-size: 20px !important;
  filter: invert(0) brightness(100) contrast(100) sepia(0);
  color: black;
}

.animate-blink {
  animation: blink 1s linear;
}

/* Blinking Animation */
@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  color: #000;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s;
}

.toast.success {
  background-color: #ffffff;
  border-left: 4px solid rgb(17, 244, 85);
}

.toast.error {
  background-color: #ffffff;
  border-left: 4px solid rgb(244, 17, 17);
}

.toast-fade {
  opacity: 0;
}

.spin-loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #4282ff 94%, #0000) top/4px 4px
      no-repeat,
    conic-gradient(#0000 30%, #4282ff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: loader-spin 1s infinite linear;
}

@keyframes loader-spin {
  100% {
    transform: rotate(1turn);
  }
}

/* Landing Banner Section */
.benefits-bg {
  height: 100%;
  /* Ensure the section covers the full viewport */
  background-image: url("https://content.qeeb.in/coltec/landing/benfits-bg.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  /* This property is key for the parallax effect */
  background-repeat: no-repeat;
  position: relative;
}

.fade-image {
  opacity: 0.5;
  transition: opacity 4s linear;
}

.fade-image.visible {
  opacity: 1;
}

.heading-t-font {
  background: linear-gradient(180deg, #fff 28.82%, #cee9ff 93.03%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.benefits-swiper .swiper-wrapper {
  margin-left: 23px;
}

.landing-faq h2 {
  display: none;
}

.marquee {
  display: grid;
  grid-template-columns: repeat(9, minmax(12vmin, 1fr));
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 0)
  );

  & img {
    --time: 25s;
    position: relative;
    aspect-ratio: 1;
    grid-column: 1;
    grid-row: 1;
    cursor: pointer;
    left: calc(100% * 8);
    animation: marquee var(--time) linear infinite;

    &:nth-of-type(1) {
      animation-delay: calc((var(--time) / 9) * (9 - 1) * -1);
    }

    &:nth-of-type(2) {
      animation-delay: calc((var(--time) / 9) * (9 - 2) * -1);
    }

    &:nth-of-type(3) {
      animation-delay: calc((var(--time) / 9) * (9 - 3) * -1);
    }

    &:nth-of-type(4) {
      animation-delay: calc((var(--time) / 9) * (9 - 4) * -1);
    }

    &:nth-of-type(5) {
      animation-delay: calc((var(--time) / 9) * (9 - 5) * -1);
    }

    &:nth-of-type(6) {
      animation-delay: calc((var(--time) / 9) * (9 - 6) * -1);
    }

    &:nth-of-type(7) {
      animation-delay: calc((var(--time) / 9) * (9 - 7) * -1);
    }

    &:nth-of-type(8) {
      animation-delay: calc((var(--time) / 9) * (9 - 8) * -1);
    }

    &:nth-of-type(9) {
      animation-delay: calc((var(--time) / 9) * (9 - 9) * -1);
    }
  }
}

@keyframes marquee {
  to {
    left: -100%;
  }
}

.fade-in-land {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-out-land {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.dynamic-slider
  .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #4282ff !important;
}

.dynamic-slider .swiper-pagination-bullets .swiper-pagination-bullet {
  background-color: #0000009c !important;
}

.dynamic-slider .swiper-pagination {
  bottom: 10px !important;
}

.benefits-swiper .swiper-scrollbar-drag {
  width: 150px !important;
  max-width: 150px;
  background: #609ff7 !important;
}

.benefits-swiper .swiper-scrollbar.swiper-scrollbar-horizontal {
  width: calc(40% - 2px);
  left: 0;
  right: 0;
  margin: auto;
}

.landing-faq .p-5.bg-\[\#f9f9f9\].rounded-md {
  background-color: #fff;
}

.landing-faq .text-\[\#767982\].text-sm.flex-1 {
  color: #000;
}

.feature-loader {
  width: 26px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid rgb(210, 232, 241);
  border-right-color: rgb(0, 110, 255);
  animation: l2 1s infinite linear;
}

@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}

.slide-right {
  animation: 3s slide-right;
}

@keyframes slide-right {
  0% {
    margin-left: -100%;
  }

  100% {
    margin-left: 0%;
  }
}

.nav-fixed-bar {
  position: fixed;
  transition: padding 0.15s ease-in-out, 0.1s box-shadow 0.25s ease-in-out,
    0.15s position 0.15s ease-in-out;
  box-shadow: 0 8px 10px 0 rgba(0, 84, 166, 0.1);
  background-color: #fff;
}

.main-class {
  display: block;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  border: 1px solid rgb(232, 232, 232);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: background-color 0.25s linear, color 0.2s linear;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
}
.modal-landing-form {
  background: url("https://content.qeeb.in/coltec/landing/demo-popup-bg.webp");
}
